import { Table } from "react-bootstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import swal from 'sweetalert';

function BuilderListTable(props) {
    function onCopy() {
        swal({
            title: "Copied",
            text: 'Has been copied',
            icon : 'success',
            button: {
                text: "OK",
                closeModal: true,
            },
        })
    }

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th width="100">Name</th>
                        <th width="50">Symbol</th>
                        <th width="100">NFT Address</th>
                        <th width="100">Price</th>
                        <th width="100">Website</th>
                        <th width="100">Status</th>
                        <th width={50}>Minted</th>
                        <th width={50}>Supply</th>
                        <th width={200}>Metadata</th>
                        <th width="50">Collateral</th>
                        <th width="300">Tool</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.list.map((l, index) => {
                            return (
                                <tr key={"listweb" + index}>
                                    <td>{l.collection?l.collection.name:''}</td>
                                    <td>{l.collection?l.collection.symbol:''}</td>
                                    <td><small>{l.nftAddress}</small></td>
                                    <td>{l.collection.price + " ONE"}</td>
                                    <td>{l.domain}</td>
                                    <td>
                                        {l.isLive ? (<span className='badge badge-success'>Online</span>) : (<span className='badge badge-danger'>Offline</span>) }                                        
                                    </td>
                                    <td>{l.collection.minted}</td>
                                    <td>{l.collection.supply}</td>
                                    <td>
                                        <CopyToClipboard text={l.collection.uri}
                                            onCopy={() => onCopy()}>
                                            <a data-tip data-for="textMetaData" className='badge badge-primary text-white'>{l.collection.uri.length > 20 ? `${l.collection.uri.substring(0, 20)}...${l.collection.uri.substring(l.collection.uri.length - 3)}` : l.collection.uri}</a>
                                        </CopyToClipboard>
                                    </td>
                                    <td>
                                        {
                                            l.isLive ? (
                                                <div>
                                                    <img src={l.deployer.image} width="64"/>
                                                    <small>{l.deployer.name} | Id : #{l.tokenId}</small>
                                                </div>
                                            ) : <></>
                                        }
                                    </td>
                                    <td>
                                        {
                                            l.isLive ? (
                                                <button className='btn btn-sm btn-danger' onClick={() => props.unStake(l.index)}>Shutdown</button>
                                            ) : (
                                                <button className='btn btn-sm btn-primary' onClick={() => {
                                                    props.upService(l);
                                                    props.setIsOpenModalStakeAgain(true);
                                                }}>Up Service</button>
                                            )
                                        }
                                        &nbsp;
                                        <button className='btn btn-sm btn-primary' onClick={() => props.setMetadata(l.nftAddress)}>Metadata</button>
                                        &nbsp;
                                        <button className='btn btn-sm btn-primary' onClick={() => props.setPrice(l.nftAddress)}>Price</button>
                                        &nbsp;
                                        <button className='btn btn-sm btn-primary' onClick={() => props.setWLAddr(l.nftAddress)}>WL Addr</button>
                                        &nbsp;
                                        {
                                            l.collection.soulboundAddr ? (
                                                <button className='btn btn-sm btn-primary' onClick={() => props.setSoulbound(l.nftAddress)}>SoulBound</button>
                                            ) : <></>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default BuilderListTable;