import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/mutan_white.png';

function FooterHomeOne({ className }) {
    function getYear() {
        return new Date().getFullYear();
    }

    return (
        <>
            <section className={`appie-footer-area mutan-bg-primary ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <img src={logo} alt="" width="200" />
                                </div>
                                <p className='text-white'>
                                    Lets Build Instantly Your NFT Platform
                                </p>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://discord.gg/QvGwnCNzVQ" target="_blank">
                                                <i className="fab fa-discord" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/mutan_harmony" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://github.com/Mutan-DAO" target="_blank">
                                                <i className="fab fa-github" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://mutandao.medium.com/" target="_blank">
                                                <i className="fab fa-medium" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-right">
                            <div className="footer-navigation">
                                <h4 className="title mutan-text-white">Other Resources</h4>
                                <ul>
                                    <li>
                                        <Link className='mutan-text-white' to="/" >Home</Link>
                                    </li>
                                    <li>
                                        <a className='mutan-text-white' href="/#how-its-work">How It's Work</a>
                                    </li>
                                    <li>
                                        <a className='mutan-text-white' href="/my-nft">My NFT</a>
                                    </li>
                                    <li>
                                        <Link className='mutan-text-white' to="/builder">Builder</Link>
                                    </li>
                                    <li>
                                        <a className='mutan-text-white' href="https://docs.mutan.one/roadmap">Roadmap</a>
                                    </li>
                                    <li>
                                        <a className='mutan-text-white' href="https://docs.mutan.one">Docs</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                               
                                <div className="copyright-text">
                                    <p className='mutan-text-white'>Copyright © {getYear()} Mutan.one. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
