import React, { useEffect, useState } from 'react';
import mutanEmpty from '../../assets/images/mutan_empty.png';
import logoGif from '../../assets/images/mutan_nft_load.gif';
import MutanABI from "../../abis/mutan.json";
import allowedNetwork from "../../config/networkId.json";
import Web3 from 'web3';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTooltip from "react-tooltip";
import swal from 'sweetalert';
const ipfsGateway = "https://cf-ipfs.com/ipfs/";


function ListNFT(props) {
    const [listMyNFT, setListMyNFT] = useState([])
    const [accountWallet, setaccountWallet] = useState("")
    

    useEffect(() => {
        if (allowedNetwork.indexOf(props.networkId) >= 0) {
            getNFT();
        }
    }, [])

    function onCopy() {
        swal({
            title: "Copied",
            text: 'Has been copied',
            icon : 'success',
            button: {
                text: "OK",
                closeModal: true,
            },
        })
    }

    async function getNFT() {
        if (props.web3) {
            const nftContract = new props.web3.eth.Contract(MutanABI[props.networkId].abi, MutanABI[props.networkId].address);

            let account = await props.web3.eth.getAccounts();
            setaccountWallet(account[0]);
            let lNFT = []
            if (account.length > 0) {
                // CHECK BALANCE NFT
                const balance = await nftContract.methods.balanceOf(account[0]).call();
                const defaultURI = await nftContract.methods.defaultURI().call()

                for (let i=0; i<balance; i++) {
                    let nftObject = {}
                    let indexed = await nftContract.methods.tokenOfOwnerByIndex(account[0], i).call();
                    nftObject.id = indexed
                    nftObject.uri = `${defaultURI}${parseInt(indexed)}.json`
                    nftObject.uri = nftObject.uri.replace('ipfs://', ipfsGateway);

                    nftObject.attr = {
                        image: `${logoGif}`,
                        name: `MutanONE #${indexed}`
                    }
                    
                    // console.log("--- nftObject", nftObject);
                    await fetch(nftObject.uri).then((response) => response.json()).then((json) => {
                        // console.log(json)
                        if (json.image) {
                            json.image = json.image.replace('ipfs://', ipfsGateway);
                            json.name = `MutanONE #${indexed}`
                        }
                        nftObject.attr = json
                    }).catch((e) => {
                        console.error(e)
                    })
                    lNFT.push(nftObject)
                }
                setListMyNFT(lNFT)
                // console.log("-- get", listMyNFT);
            }
        }
    }

    function myNFTRender() {
        // console.log("--- list ", listMyNFT);
        if (listMyNFT.length === 0 ) {
            return(
                <div className="d-flex justify-content-center">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-team-item mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms">
                            <div className="thumb">
                                <img src={mutanEmpty} alt="Mutan NFT Empty" />
                            </div>
                            <div className="content text-center">
                                <h3 className="title" style={{marginBottom: 0, paddingBottom: 0}}>NFT EMPTY</h3>
                                <small>Token Id: Not Found</small>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    {
                        listMyNFT.map((l, index) => {
                            return (
                                <div className="col-lg-3 col-md-6" key={index}>
                                    <div
                                        className="appie-team-item mt-30 wow animated fadeInUp"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="200ms">
                                        <div className="thumb">
                                            <img src={l.attr?.image} alt="Mutan NFT" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="title" style={{marginBottom: 0, paddingBottom: 0}}>{l.attr?.name}</h5>
                                            <small>Token ID: {l.id}
                                            <br /> 
                                            <CopyToClipboard text={Web3.utils.toHex(accountWallet.substring(24,32)+"T"+l.id)}
                                            onCopy={() => onCopy()}>
                                            <a data-tip data-for="textGiftCode" className='btn btn-block btn-sm btn-primary text-white'>Gift Code<br />{ Web3.utils.toHex(accountWallet.substring(24,32)+"T"+l.id) }</a>
                                            </CopyToClipboard>
                                            </small>
                                            <ReactTooltip id="textGiftCode" place="bottom" effect="solid">
                                                Click to Copy Gift Code
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
    }

    return (
        <>
            <section id='mymy-nft' className="appie-team-area pt-90 pb-100" style={{background: '#fff'}}>
                <div className="container">
                    {myNFTRender()}
                </div>
            </section>
            
        </>
    );
}

export default ListNFT;
