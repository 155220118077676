import React from 'react';
import IconOne from '../../assets/images/mutan-icon1.png';
import IconTwo from '../../assets/images/mutan-icon2.png';
import IconThree from '../../assets/images/mutan-icon3.png';
import IconFour from '../../assets/images/mutan-icon4.png';

function ServicesHomeTwo({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title mutan-title-bg">
                            Awesome Features<br />
                            </h3>
                            <p>What is the advantage of holding this MutanNFT ?</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp mutan-shadow"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms">
                            {/* <div className="icon"> */}
                                <img src={IconOne} alt=""  width={'200px'}/>
                                {/* <span>1</span> */}
                            {/* </div> */}
                            <h4 className="appie-title mutan-text-services">Whitelable</h4>
                            <p className='mutan-desc-services'>Build Your Own Website Minting NFT Collections</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp mutan-shadow"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms">
                            {/* <div className="icon"> */}
                                <img src={IconTwo} alt=""  width={'200px'}/>
                                {/* <span>2</span> */}
                            {/* </div> */}
                            <h4 className="appie-title mutan-text-services">No Coding</h4>
                            <p className='mutan-desc-services'>You Don't Need Coding To Build Website Minting NFT</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp mutan-shadow"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            {/* <div className="icon"> */}
                                <img src={IconThree} alt="" width={'200px'}/>
                                {/* <span>3</span> */}
                            {/* </div> */}
                            <h4 className="appie-title mutan-text-services">Secure</h4>
                            <p className='mutan-desc-services'>Your Smart Contract is Secure By Default</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp mutan-shadow"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms">
                            {/* <div className="icon"> */}
                                <img src={IconFour} alt="" width={'175px'}/>
                                {/* <span>4</span> */}
                            {/* </div> */}
                            <h4 className="appie-title mutan-text-services">Cross Platforms</h4>
                            <p className='mutan-desc-services'>Our Smart Contract Support All Platform Marketplace</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeTwo;
