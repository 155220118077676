import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Tab } from "react-bootstrap";
import Web3 from 'web3';
import useToggle from '../../Hooks/useToggle';
import Drawer from '../Mobile/Drawer';
import HomeOneHeader from './HomeOneHeader';
// import FooterHomeOne from './FooterHomeOne';
import MutanABI from "../../abis/mutan.json";
import ListPartner from "../../abis/nftStaked.json";
import BuilderABI from "../../abis/builder.json";
import swal from 'sweetalert';
import BuilderList from './BuilderList';
import allowedNetwork from "../../config/networkId.json";
import { Link } from 'react-router-dom';
import Background from '../../assets/images/bg_mutan.jpg';
import BackToTop from '../BackToTop';
import FooterHomeOne from './FooterHomeOne';
import ListNftSoulBound from './ListNftSoulbound';
import ListDomainAllowed from '../../abis/listDomain.json';

function Builder(props) {
    const [accountLoaded, setAccountLoaded] = useState(null);
    const [fullAccount, setFullAccount] = useState([]);
    const [loading, setLoading] = useState(false);
    const [approvedMutan, setApprovedMutan] = useState(false);
    const [listNft, setListNFT] = useState([]);
    const [listDomain, setListDomain] = useState([]);
    const [listPartner, setListPartner] = useState([]);
    const [price, setPrice] = useState(0);
    const [inputField, setInputField] = useState({
        domain: '',
        collectionName: '',
        collectionSymbol: '',
        supply: 0,
        mutanId: 0
    })
    const [selectedNFT, setSelectedNFT] = useState("0x0");
    
    const [drawer, drawerAction] = useToggle(false);

    const wallet = window.ethereum ? new Web3(window.ethereum) : null;
    const [web3, setWeb3] = useState(wallet)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalStakeAgain, setIsOpenModalStakeAgain] = useState(false);
    const [selectUpService, setSelectUpService] = useState(null);
    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    const [modalIsOpen3, setIsOpen3] = React.useState(false);
    const [typeDomain, setTypeDomain] = React.useState("0");

    function modalClose () {
        setIsOpen(false);
    }

    function modalClose2 () {
        setIsOpen2(false);
    }

    function modalClose3 () {
        setIsOpen3(false);
    }

    useEffect(() => {
        checkAccount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        getListPartners();
    }, [])
    
    async function checkAccount() {
        try {
            if (web3) {
                let account =  await web3.eth.getAccounts();
                // console.log(account);
    
                let accountlastChar = account[0].substr(account[0].length - 4); // => "Tabs1"
                let accountFirstChar = account[0].substring(0, 6)
                setAccountLoaded(accountFirstChar+"..."+accountlastChar);
                setFullAccount(account);

                // console.log("-- account", account[0]);
                // console.log("-- methods", web3.methods);
            } else {
                // console.log('Not Connected')
            }
        } catch {
            // console.log("-- Error");
        }
    }

    async function getListPartners() {
        if (web3) {
            if (ListPartner[props.networkId]) {
                setListPartner(ListPartner[props.networkId])
            }
            if (ListDomainAllowed[props.networkId]) {
                setListDomain(ListDomainAllowed[props.networkId])
            }
        }
    }
    
    async function connectWallet() {
        if (window.ethereum) {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            let w = new Web3(window.ethereum);
            setWeb3(w)
            checkAccount();
            // console.log("Wallet connected")
            return true;
        } else {
            alert('Can\'t Connect Wallet')
        }
    }

    function openModal() {
        setIsOpen(true);
        afterOpenModal();
    }

    function openModal2() {
        setIsOpen2(true);
    }

    function openModal3() {
        setIsOpen3(true);
    }

    async function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function getNFT(_address) {
        if (allowedNetwork.indexOf(props.networkId) >= 0) {
            if (web3) {
                /* SET PRICE */
                const builderContract = new web3.eth.Contract(BuilderABI[props.networkId].abi, BuilderABI[props.networkId].address);
                const priceNFT = parseInt(await builderContract.methods.prices(_address).call()) / 1e18;
                setPrice(priceNFT);
    
                const nftContract = new web3.eth.Contract(MutanABI[props.networkId].abi, _address);
                // CHECK APPROVED NFT PARTNERS
                const isApproved = await nftContract.methods.isApprovedForAll(fullAccount[0], BuilderABI[props.networkId].address).call();
                setApprovedMutan(isApproved);
                // console.log("IS APPROVED", isApproved);
    
                let account = await web3.eth.getAccounts()
                let myNft = []
                if (account.length > 0) {
                    // CHECK BALANCE NFT
                    const balance = await nftContract.methods.balanceOf(account[0]).call();
                    const defaultURI = await nftContract.methods.defaultURI().call()
    
                    for (let i=0; i<balance; i++) {
                        let nftObject = {}
                        let indexed = await nftContract.methods.tokenOfOwnerByIndex(account[0], i).call();
                        let name = await nftContract.methods.name().call();
    
                        nftObject.id = indexed
                        nftObject.uri = `${defaultURI}${indexed}.json`
                        nftObject.name = `${name} #${indexed}`
                        // console.log(nftObject)
                        myNft.push(nftObject)
                    }
                    setListNFT(myNft);
                    setSelectedNFT(_address);
                }
            }
        } else {
            setLoading(false);
        }
    }

    async function deploy() {
        if (web3) {
            const builderContract = new web3.eth.Contract(BuilderABI[props.networkId].abi, BuilderABI[props.networkId].address);
            let account = await web3.eth.getAccounts()
            let options = {
                from: account[0],
                value: price * 1e18
            }
            let tokenId = parseInt(inputField.mutanId);
            let domain = typeDomain != "0" ? `${inputField.domain}.${typeDomain}` : inputField.domain;
            let nftName = inputField.collectionName;
            let nftSymbol = inputField.collectionSymbol;
            let nftSupply = parseInt(inputField.supply) || 0;

            let gas = await builderContract.methods.build(selectedNFT, tokenId, domain, nftName, nftSymbol, nftSupply).estimateGas(options).catch((error) => {
                swal({
                    title: "Error",
                    text: `${error.message}`,
                    icon: "warning",
                    button: "Close"
                })
                return 0;
            });
            if (gas === 0) return;

            builderContract.methods.build(selectedNFT, tokenId, domain, nftName, nftSymbol, nftSupply).send({
                ...options,
                gas: gas
            })
            .on('transactionHash', (hash) => {
                // console.log('Build with hash', hash)
                setLoading(true);
            })
            .on('receipt', (receipt) => {
                // console.log('Receipt', receipt);
                setLoading(false);
                swal({
                    title: "Success",
                    text: `Successfull Create New Collection`,
                    icon: "success",
                    button: "Close"
                }).then(() => {
                    window.location.reload();
                })
            })
            .on('error', (error) => {
                console.error(error)
                swal({
                    title: "Error",
                    text: `${error.message}`,
                    icon: "warning",
                    button: "Close"
                })
            })
        }
    }

    async function approve(_address) {
        const contract = new web3.eth.Contract(MutanABI[props.networkId].abi, _address);
        let options = {
            from: fullAccount[0]
        }
        let gas = await contract.methods.setApprovalForAll(BuilderABI[props.networkId].address, true).estimateGas(options);
        contract.methods.setApprovalForAll(BuilderABI[props.networkId].address, true).send({
            ...options,
            gas: gas
        })
        .on('transactionHash', (hash) => {
            // console.log('Build with hash', hash)
            setLoading(true);
        })
        .on('receipt', (receipt) => {
            // console.log('Receipt', receipt);
            setLoading(false);
            setApprovedMutan(true);
        })
        .on('error', (error) => {
            console.error(error)
            setLoading(false);
            setApprovedMutan(false);
        })
    }

    function inputsHandler(e) {
        const { name, value } = e.target;
        setInputField((prevState) => ({
          ...prevState,
          [name]: value,
        }));        
    }

    function selectPartner(e) {
        if (e.target.value !== "0") {
            getNFT(e.target.value);
        }
    }

    function selectTypeDomain(e) {
        if (e.target.value !== "0") {
            setTypeDomain(e.target.value);
        } else {
            setTypeDomain("0");
        }
    }

    async function unStake(_index) {
        return swal({
            title: "Shutdown Website ?",
            text: "When you shutdown this website, you can't open your website. but your collections still available. sometime you can make it online again",
            buttons: [{
                visible: true,
                text: "Cancel It",
                icon: 'warning',
                closeModal: true
            },{
                visible: true,
                text: "Shutdown",
                value: "yes",
                icon: 'error',
                closeModal: false
            }
            ]
        }).then((text) => {
            if (text === "yes") {
                setLoading(true);
                const down = () => {
                    return new Promise(async (resolve, reject) => {
                        const contract = new web3.eth.Contract(BuilderABI[props.networkId].abi, BuilderABI[props.networkId].address);
                        let options = {
                            from: fullAccount[0]
                        }
                        let gas = await contract.methods.down(_index).estimateGas(options).catch((e) => {
                            console.error(e)
                            return 0
                        });
                        if (gas === 0) {
                            return;
                        }
                        contract.methods.down(_index).send({
                            ...options,
                            gas: gas
                        })
                        .on('transactionHash', (hash) => {
                            // console.log('Build with hash', hash)
                        })
                        .on('receipt', (receipt) => {
                            // console.log('Receipt', receipt);
                            setLoading(false);
                            return resolve({message: "Success", data: receipt})
                        })
                        .on('error', (error) => {
                            console.error(error)
                            setLoading(false);
                            return reject(error)
                        })
                    })
                }

                return down();
            }
        }).then((result) => {
            // console.log("RESULT", result)
            if(result === undefined) {
                swal({
                    title: "Failed",
                    text: "",
                    icon: "error"
                })
            } else {
                if(result.data.status === true) {
                    swal({
                        title: "Success",
                        text: "Website Shutting Down",
                        icon: "success"
                    })
                } else {
                    swal({
                        title: "",
                        text: "",
                        icon: "error"
                    })
                }
            }
           
            // 
        }).catch((e) => {
            swal({
                title: "Error",
                text: e.message,
                icon: "warning"
            })
        })
    }

    async function restart() {
        // console.log(selectedNFT, inputField, selectUpService);
        // console.log(selectedNFT, parseInt(inputField.mutanId), selectUpService.domain, selectUpService.nftAddress)
        const builderContract = new web3.eth.Contract(BuilderABI[props.networkId].abi, BuilderABI[props.networkId].address);
        let account = await web3.eth.getAccounts()
        let options = {
            from: account[0]
        }

        let gas = await builderContract.methods.upService(selectUpService.index, selectedNFT, parseInt(inputField.mutanId), selectUpService.domain, selectUpService.nftAddress).estimateGas(options).catch((error) => {
            swal({
                title: "Error",
                text: `${error.message}`,
                icon: "warning",
                button: "Close"
            })
            return 0;
        });
        if (gas === 0) return;
        setLoading(true);

        builderContract.methods.upService(selectUpService.index, selectedNFT, parseInt(inputField.mutanId), selectUpService.domain, selectUpService.nftAddress).send({
            ...options,
            gas: gas
        })
        .on('transactionHash', (hash) => {
            // console.log('Build with hash', hash)
            setLoading(true);
        })
        .on('receipt', (receipt) => {
            // console.log('Receipt', receipt);
            setLoading(false);
            swal({
                title: "Success",
                text: `Successfull Restart Website`,
                icon: "success",
                button: "Close"
            })
        })
        .on('error', (error) => {
            console.error(error)
            setLoading(false);
            swal({
                title: "Error",
                text: `${error.message}`,
                icon: "warning",
                button: "Close"
            })
        })
    }

    var sectionStyle = {
        width: "100%",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`
    };

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} connect={connectWallet}/>
            <HomeOneHeader action={drawerAction.toggle} web3={web3} accountLoaded={accountLoaded} fullAccount={fullAccount} connect={connectWallet}/>
            <section className="appie-team-area" style={sectionStyle}>
                <div className="container">
                <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="appie-hero-content">
                                <br />
                                <h1 className="appie-title mutan-text-white pt-200 text-center">Build Your Collection As Website</h1>
                                <p className='text-center' style={{color: "#fff", fontWeight:700, fontSize:20}}>You Can Own Website Minting For Your Collection NFT</p>
                                <br />
                                {
                                    accountLoaded ? allowedNetwork.indexOf(props.networkId) >= 0 ? (
                                        <div className='row justify-content-lg-center justify-content-md-center justify-content-sm-center'>
                                            <div className='col-md-4 col-lg-3 col-sm-4'>
                                                <button className='btn btn-lg  btn-primary mutan-bg-build' onClick={openModal}>Create Collection</button>                                                
                                            </div>
                                            <div className='col-md-4 col-lg-3 col-sm-4'>
                                                <button className='btn btn-lg btn-info mutan-bg-connect' onClick={openModal3}>Import Collections</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='row'>
                                            <div className='col-md-12 col-lg-12 col-sm-12'>
                                                <Link className='btn btn-lg btn-block btn-primary' to="/">MINT MUTAN NFT</Link>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='row'>
                                            <div className='col-md-12 col-lg-12 col-sm-12 text-center'>
                                                <button className='btn btn-lg btn-primary' onClick={connectWallet}>Connect Wallet</button>                                                            
                                            </div>
                                        </div>
                                    )
                                }
                                <br />
                                <br />
                                <br />
                                
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms">
                                    {/* <img src={heroThumbPrimary} alt="" width="50%"/>
                                    <img src={heroThumbSecondary} alt="" width="50%"/> */}
                                </div>
                            </div>    
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='container'>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='appie-team-area'>
                <div className='pt-20 pl-20 pr-20'>
                    <div className="row align-items-center">
                        <div className='col-lg-12'>
                            <Tabs
                                defaultActiveKey="list-website"
                                id="uncontrolled-tab-website"
                                className="mb-3"
                                >
                                <Tab eventKey="list-website" title="List Website">
                                    {
                                        accountLoaded ? (
                                            <BuilderList
                                                web3={web3}
                                                fullAccount={fullAccount}
                                                connect={connectWallet}
                                                unStake={unStake}
                                                upService={setSelectUpService}
                                                update={loading}
                                                comingSoon={setIsOpen2}
                                                networkId={props.networkId}
                                                setIsOpenModalStakeAgain={setIsOpenModalStakeAgain}></BuilderList>
                                        ) : <></>
                                    }
                                </Tab>
                                <Tab eventKey="list-soulbound" title="List NFT Soulbound">
                                    {
                                        accountLoaded ? (
                                            <ListNftSoulBound
                                                web3={web3}
                                                fullAccount={fullAccount}
                                                connect={connectWallet}
                                                unStake={unStake}
                                                upService={setSelectUpService}
                                                update={loading}
                                                comingSoon={setIsOpen2}
                                                networkId={props.networkId}
                                                setIsOpenModalStakeAgain={setIsOpenModalStakeAgain}/>
                                        ) : <></>
                                    }
                                </Tab>
                                <Tab eventKey="list-whitelist" title="Whitelist Addr">
                                    <small>Coming Soon</small>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>

            <FooterHomeOne />
            <BackToTop />

            <Modal show={modalIsOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title>Create Collection</Modal.Title>
                    <button type="button" className="btn bt-sm btn-danger" aria-label="Close" onClick={() => modalClose()}>X</button>
                </Modal.Header>
                <Modal.Body>
                <form>
                    <div className='form-group'>
                        <label>Select Type Domain</label>
                        <select className='form-control' name="typeDomain" onChange={selectTypeDomain}>
                            <option value="0">My Domain</option>
                            {
                                listDomain.map((l,i) => {
                                    return (
                                        <option value={l} key={i}>{"Sub Domain *."+l}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    {
                        typeDomain == "0" ? (
                            <div className='form-group'>
                                <label>Domain</label>
                                <input className='form-control' placeholder='example.com' name="domain" value={inputField.domain} onChange={inputsHandler} />
                            </div>
                        ) : (
                            <div className='form-group'>
                                <label>Sub Domain</label>
                                <div className='input-group'>
                                    <input className='form-control' placeholder='yourcollection' name="domain" value={inputField.domain} onChange={inputsHandler} />
                                    <div className='input-group-append'>
                                        <span className='input-group-text'>{"*."+typeDomain}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }                    
                    <div className='form-group'>
                        <label>Collection Name</label>
                        <input className='form-control' name="collectionName" value={inputField.collectionName} onChange={inputsHandler} />
                    </div>
                    <div className='form-group'>
                        <label>Collection Symbol</label>
                        <input className='form-control' name="collectionSymbol" value={inputField.collectionSymbol} onChange={inputsHandler} />
                    </div>
                    <div className='form-group'>
                        <label>Max Supply</label>
                        <input className='form-control' type="number" name="supply" value={inputField.supply} onChange={inputsHandler} />
                    </div>
                    <div className='form-group'>
                        <label>Select Our Partners</label>
                        <select className='form-control' name="creatorAddr" onChange={selectPartner}>
                            <option value="0">Select Creator</option>
                            {/* <option value={MutanABI[props.networkId].address}>Mutan Official</option> */}
                            {
                                listPartner.map((l, i) => {
                                    return (
                                        <option value={l.address} key={i}>{l.name}</option>
                                    );
                                })
                            }                            
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Select NFT To Stake</label>
                        <select className='form-control' name="mutanId" defaultValue={inputField.mutanId} onChange={inputsHandler}>
                            <option value="0">Select NFT</option>
                            {
                                listNft.map((l, i) => {
                                    return (
                                        <option value={l.id} key={i}>{l.name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                </form>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <small>Price : </small>
                        <b>{price} ONE</b>
                    </div>
                    <div></div>
                    <div className='pull-right'>
                        {
                            approvedMutan ? (
                                <button type="button" onClick={deploy} className='btn btn-block btn-lg btn-primary' disabled={loading}>{loading ? "Loading ...." : "Deploy" }</button>
                            ) : (
                                <button type="button" onClick={() => approve(selectedNFT)} className='btn btn-block btn-lg btn-primary'>Approve</button>
                            )
                        }
                    </div>
                </Modal.Footer>
               
            </Modal>

            <Modal show={modalStakeAgain} onHide={() => setIsOpenModalStakeAgain(false)}>
                <Modal.Header>
                    <Modal.Title>Stake Collection</Modal.Title>
                    <button type="button" className="btn bt-sm btn-danger" aria-label="Close" onClick={() => setIsOpenModalStakeAgain(false)}>X</button>
                </Modal.Header>
                <Modal.Body>
                <form>
                    <div className='form-group'>
                        <label>Select Our Partners</label>
                        <select className='form-control' name="creatorAddr" onChange={selectPartner}>
                            <option value="0">Select Creator</option>
                            {/* <option value={MutanABI[props.networkId].address}>Mutan Official</option> */}
                            {
                                listPartner.map((l, i) => {
                                    return (
                                        <option value={l.address} key={i}>{l.name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Select NFT To Stake</label>
                        <select className='form-control' name="mutanId" defaultValue={inputField.mutanId} onChange={inputsHandler}>
                            <option value="0">Select NFT</option>
                            {
                                listNft.map((l, i) => {
                                    return (
                                        <option value={l.id} key={i}>{l.name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                </form>
                </Modal.Body>
                <Modal.Footer>
                    <div></div>
                    <div className='pull-right'>
                        {
                            approvedMutan ? (
                                <button type="button" onClick={restart} className='btn btn-block btn-lg btn-primary' disabled={loading}>{loading ? "Loading ...." : "Restart" }</button>
                            ) : (
                                <button type="button" onClick={() => approve(selectedNFT)} className='btn btn-block btn-lg btn-primary'>Approve</button>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={modalIsOpen2} onHide={modalClose2}>
                <Modal.Header>
                    <Modal.Title>Coming Soon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>We Will Deploy it ASAP !!</h4>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-block btn-danger" aria-label="Close" onClick={() => modalClose2()}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalIsOpen3} onHide={modalClose3}>
                <Modal.Header>
                    <Modal.Title>Import Collections</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Coming Soon ..</h4>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-block btn-danger" aria-label="Close" onClick={() => modalClose3()}>Close</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Builder;