import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul className='mutan-main-menu mutan-text-white'>
                <li>
                    <Link className='mutan-text-white' to="/" >Home</Link>
                </li>
                <li>
                    <a className='mutan-text-white' href="/#how-its-work">How It's Work</a>
                </li>
                <li>
                    <Link className='mutan-text-white' to="/my-nft">My NFT</Link>
                </li>
                <li>
                    <Link className='mutan-text-white' to="/builder">Builder</Link>
                </li>
                <li>
                    <a className='mutan-text-white' target="_blank" href="https://docs.mutan.one/roadmap">Roadmap</a>
                </li>
                <li>
                    <a className='mutan-text-white' target="_blank" href="https://docs.mutan.one">Docs</a>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
