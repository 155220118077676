import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import BuilderABI from "../../abis/builder.json";
// import MutanABI from "../../abis/mutan.json";
import CollectionABI from "../../abis/collection.json";
import SoulBoundABI from "../../abis/soulbound.json";
import allowedNetwork from "../../config/networkId.json";
import Loader from '../Helper/Loader';
import { Table } from "react-bootstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function ListNftSoulBound(props) {
    const [loadingPage, setLoadingPage] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (allowedNetwork.indexOf(props.networkId) >= 0) {
            getBalance();
            setLoadingPage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onCopy() {
        swal({
            title: "Copied",
            text: 'Has been copied',
            icon : 'success',
            button: {
                text: "OK",
                closeModal: true,
            },
        })
    }

    async function getBalance() {
        if (props.web3) {
            // console.log("Get Balance");
            let account = await props.web3.eth.getAccounts()
            let listWeb = [];

            if (account.length > 0) {
                let builderContract = new props.web3.eth.Contract(BuilderABI[props.networkId].abi, BuilderABI[props.networkId].address);
                let b = await builderContract.methods.balanceOf(account[0]).call();
                
                // console.log("Fetching RPC");
                for (let i=0; i<b; i++) {
                    let object = {}
                    let websiteInfo = await builderContract.methods.stakedIndex(account[0], i).call();
                    let collectionContract = new props.web3.eth.Contract(CollectionABI[props.networkId].abi, websiteInfo.nftAddress);
                    let soulboundAddr = collectionContract.methods.soulboundAddr?await collectionContract.methods.soulboundAddr().call():"0x0000000000000000000000000000000000000000";
                    if (soulboundAddr!="0x0000000000000000000000000000000000000000") {
                        collectionContract = new props.web3.eth.Contract(SoulBoundABI[props.networkId].abi, soulboundAddr);

                        /* COLLECTION USER */
                        let colOrigin = await collectionContract.methods.nftOrigin().call();
                        let colName = await collectionContract.methods.name().call();
                        let colSymbol = await collectionContract.methods.symbol().call();
                        let colMintPrice = await collectionContract.methods.mintPrice().call();
                        let colMinted = await collectionContract.methods.totalSupply().call();
                        let colDefaultURI = await collectionContract.methods.defaultURI().call();
     
                        object = {
                            index: i,
                            originAddress: colOrigin,
                            nftAddress: soulboundAddr,
                            collection: {
                                name: colName,
                                symbol: colSymbol,
                                price: parseInt(colMintPrice/1e18),
                                minted: parseInt(colMinted),
                                uri : colDefaultURI
                            }
                        }
                        listWeb.push(object);
                    }

                }
                setList(listWeb);
                setLoadingPage(false);
            }
        }
    }

    async function setPrice(_address) {
        swal({
            title: "Set Your NFT Price",
            text: 'Write price as $ONE',
            content: "input",
            button: {
                text: "Save",
                closeModal: false,
            },
        })
        .then(async result => {
            if (!isNaN(result)) {
                let accounts = await props.web3.eth.getAccounts()
                let contract = new props.web3.eth.Contract(SoulBoundABI[props.networkId].abi, _address);
                let gas = await contract.methods.setPrice(props.web3.utils.toBN(parseInt(result) * 1e18)).estimateGas({from: accounts[0], to: _address});
                
                contract.methods.setPrice(props.web3.utils.toBN(parseInt(result) * 1e18)).send({
                    from: accounts[0],
                    gas: gas
                })
                .on('transactionHash', (hash) => {
                    // console.log('Minting with hash', hash)
                })
                .on('receipt', (receipt) => {
                    // console.log('Receipt', receipt);
                    getBalance();
                    swal({
                        title: "Success",
                        text: `Set Price Success`,
                        icon: "success",
                        button: "Close"
                    })
                })
                .on('error', (error) => {
                    swal("Oh noes!", "Error, Please Try Again", "error");
                })
            } else {
                swal("Oh noes!", "Error, Please Try Again", "error");
            }
        })
        .catch(err => {
            // console.log(err)
            if (err) {
                swal("Oh noes!", "Error, Please Try Again", "error");
            } else {
                swal.stopLoading();
                swal.close();
            }
        });
    }

    async function setMetadata(_address) {
        swal({
            title: "Setting IPFS Metadata",
            text: 'Change it with your url / ipfs metadata directory',
            content: "input",
            button: {
                text: "Save",
                closeModal: false,
            },
        })
        .then(async result => {
            if (result) {
                let accounts = await props.web3.eth.getAccounts()
                let contract = new props.web3.eth.Contract(SoulBoundABI[props.networkId].abi, _address);
                let gas = await contract.methods.changeDefaultURI(result).estimateGas({from: accounts[0], to: _address});
                
                contract.methods.changeDefaultURI(result).send({
                    from: accounts[0],
                    gas: gas
                })
                .on('transactionHash', (hash) => {
                    // console.log('Change ipfs with hash', hash)
                })
                .on('receipt', (receipt) => {
                    // console.log('Receipt', receipt);
                    getBalance();
                    swal({
                        title: "Success",
                        text: `Success Change URL Metadata`,
                        icon: "success",
                        button: "Close"
                    })
                })
                .on('error', (error) => {
                    swal("Oh noes!", "Error, Please Try Again", "error");
                })
            } else {
                swal("Oh noes!", "Error, Please Try Again", "error");
            }
        })
        .catch(err => {
            // console.log(err)
            if (err) {
                swal("Oh noes!", "Error, Please Try Again", "error");
            } else {
                swal.stopLoading();
                swal.close();
            }
        });
    }

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th width="100">Name</th>
                        <th width="50">Symbol</th>
                        <th width="100">NFT Address</th>
                        <th width="100">Origin Address</th>
                        <th width="100">Price</th>
                        <th width={50}>Minted</th>
                        <th width={200}>Metadata</th>
                        <th width="300">Tool</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((l, index) => {
                            return (
                                <tr key={"listweb" + index}>
                                    <td>{l.collection?l.collection.name:''}</td>
                                    <td>{l.collection?l.collection.symbol:''}</td>
                                    <td><small>{l.nftAddress}</small></td>
                                    <td><small>{l.originAddress}</small></td>
                                    <td>{l.collection.price + " ONE"}</td>
                                    <td>{l.collection.minted}</td>
                                    <td>
                                        <CopyToClipboard text={l.collection.uri}
                                            onCopy={() => onCopy()}>
                                            <a data-tip data-for="textMetaData" className='badge badge-primary text-white'>{l.collection.uri.length > 20 ? `${l.collection.uri.substring(0, 20)}...${l.collection.uri.substring(l.collection.uri.length - 3)}` : l.collection.uri}</a>
                                        </CopyToClipboard>
                                    </td>
                                    <td>
                                        <button className='btn btn-sm btn-primary' onClick={() => setMetadata(l.nftAddress)}>Metadata</button>
                                        &nbsp;
                                        <button className='btn btn-sm btn-primary' onClick={() => setPrice(l.nftAddress)}>Price</button>                                        
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}
export default ListNftSoulBound;