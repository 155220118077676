import React from 'react';
import swal from 'sweetalert';

function WrongNetwork(props) {
    const changeNetwork = async (e) => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: props.web3.utils.toHex(props.chainId)}]
                })
                window.location.reload();
            } catch (err) {
                swal({
                    title: err.code,
                    text: err.message,
                    icon: 'danger'
                })
            }
        }
    };
    return (
        <>
            <div className="appie-error-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="appie-error-content text-center">
                                <span>Sorry!</span>
                                <h3 className="title">You Are Wrong Network</h3>
                                <p>
                                    Please Check Network Before, Then Refresh This Page
                                </p>
                                <br/>
                                <button className='btn btn-block btn-primary' onClick={(e) => changeNetwork(e)}>
                                    Change Network
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WrongNetwork;