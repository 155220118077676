import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeOne from './components/HomeOne';
import MyNFT from './components/HomeOne/MyNFT';
import HomeTwo from './components/HomeOne/index2';
import Builder from './components/HomeOne/Builder';
import Web3 from 'web3';
import swal from "sweetalert";

function Routes() {
    const [loading, setLoading] = useState(true);
    const wallet = window.ethereum ? new Web3(window.ethereum) : null;
    const [web3, setWeb3] = useState(wallet);
    const [networkId, setNetworkId] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);

        checkNetwork();
    });

    async function checkNetwork() {
        if (web3) {
            let networkId = await web3.eth.getChainId()
            setNetworkId(networkId);
        }
    }

    if (window.ethereum) {
        window.ethereum.on('networkChanged', function(networkId){
            // console.log('networkChanged',networkId);
            setNetworkId(networkId);
        });
    }

    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={() => <HomeTwo networkId={networkId}/>} />
                            <Route exact path="/home2" component={() => <HomeOne networkId={networkId}/>} />
                            <Route exact path="/my-nft" component={() => <MyNFT networkId={networkId}/>}/>
                            <Route exact path="/builder" component={() => <Builder networkId={networkId}/>}/>
                            <Route exact path="/error" component={Error} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
