import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Web3 from 'web3';
import mutans from '../../assets/images/mutans.png';
import Background from '../../assets/images/bg_mutan.jpg';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';
import mintingGIF from '../../assets/images/mintingGIF.gif';
import MutanABI from "../../abis/mutan.json";
import swal from 'sweetalert';
import LoaderMinting from '../Helper/LoaderMinting';
import allowedNetwork from "../../config/networkId.json";
import { Modal} from "react-bootstrap";

function HeroHomeOne(props) {
    const [contractNFT, setContractNFT] = useState(null);
    const [minting, setMinting] = useState(false);
    const [price, setPrice] = useState(0);
    const [priceOriginal, setPriceOriginal] = useState(0);
    const [minted, setMinted] = useState(0);
    const [supply, setSupply] = useState(0);
    const [giftCode, setGiftCode] = useState(0);
    const [priceGiftCode, setPriceGiftCode] = useState(0);
    const [errorPriceGiftCode, setErrorPriceGiftCode] = useState("");

    const mountedRef = useRef(true)

    useEffect(() => {
        checkNFT();
        return () => { mountedRef.current = false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function changeGiftCode(e) {
        // console.log("--- "+e.target.value)
        // return e.target.value;
        if (e.target.value !== "") {
            const contract = new props.web3.eth.Contract(MutanABI[props.networkId].abi, MutanABI[props.networkId].address);

            try {
                let passGiftCode = 0;
                setGiftCode(e.target.value);
                passGiftCode = Web3.utils.toUtf8(e.target.value);
                // console.log(passGiftCode);
                passGiftCode = passGiftCode.split("T")[1];
                // console.log(passGiftCode, passGiftCode === " ", passGiftCode.length)
                let priceDiscount = await contract.methods.mintPrice().call();

                if (passGiftCode === "" || isNaN(passGiftCode)) {
                    setPrice((priceDiscount/1e18));
                    setPriceOriginal(0);
                    // console.log("---- xx "+e.target.value)
                    setErrorPriceGiftCode("Invalid Gift Code")    
                } else {
                    setPriceGiftCode(passGiftCode);
    
                    let discount = await contract.methods.discount().call();
                    
                    setPrice((priceDiscount/1e18)-(discount/1e18)); 
                    setPriceOriginal(priceDiscount/1e18);
                    setErrorPriceGiftCode("");
                }
            } catch (error) {
                let priceDiscount = await contract.methods.mintPrice().call();
                setPrice((priceDiscount/1e18)); 
                setPriceOriginal(0);
                // console.log("---- xx "+e.target.value)
                setErrorPriceGiftCode("Invalid Gift Code")
                // swal({
                //     title: "Error",
                //     text: 'Invalid Gift Code',
                //     icon : 'error',
                //     button: {
                //         text: "OK",
                //         closeModal: true,
                //     },
                // }) 
            } 
        } else {
            setErrorPriceGiftCode("");
        }
    }

    async function checkNFT() {
        try {
            if (props.web3) {
                const contract = new props.web3.eth.Contract(MutanABI[props.networkId].abi, MutanABI[props.networkId].address);
                setContractNFT(contract);

                /* CHECK PRICE NFT AS TOKEN */
                const priceNFT = await contract.methods.mintPrice().call();
                const mintedNFT = await contract.methods.totalSupply().call();
                const supplyNFT = await contract.methods.MAX_SUPPLY().call();
                // console.log("---- load", priceNFT);
                setPrice(parseInt(priceNFT) / 1e18);
                setMinted(parseInt(mintedNFT));
                setSupply(parseInt(supplyNFT));
            } else {
                // console.log("-- Error Connect 1");
            }
        } catch{
            // console.log("-- Error Connect 1.1");
        }
        
    }

    async function mintNFT() {
        // console.log("---- value hax to asci", passGiftCode);
        // return passGiftCode;

        if (allowedNetwork.indexOf(props.networkId) >= 0) {
            if (contractNFT) {
                let valuePrice = props.web3.utils.toWei(parseInt(price).toString(), "ether")
                // console.log("PRICE MINT", valuePrice)

                let gas = await contractNFT.methods.mint(priceGiftCode).estimateGas({from: props.account[0], to: MutanABI.address, value: valuePrice});
                
                contractNFT.methods.mint(priceGiftCode).send({
                    from: props.account[0],
                    gas: gas,
                    value: props.web3.utils.toWei(price.toString(), "ether")
                })
                .on('transactionHash', (hash) => {
                    // console.log('Minting with hash', hash)
                    setMinting(true);
                })
                .on('receipt', (receipt) => {
                    // console.log('Receipt', receipt);
                    // loadingMinting(false);
                    setMinting(false);
                    swal({
                        title: "Success",
                        text: `Congrats You Got 1 Mutan NFT`,
                        icon: "success",
                        button: "Close"
                    }).then(() => {
                        window.location = "/my-nft";
                    })
                })
                .on('error', (error) => {
                    console.error(error)
                })
            }
        } else {
            swal('Warning', `Network ${props.networkId} not allowed`, 'error')
        }
    }

    var sectionStyle = {
        width: "100%",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`
    };

    var sectionStyle2 = {
        width: "100%",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#59c5d5'
    };

    return (
        <>
            {minting ? (
                <Modal show={minting}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={true} >
                    {/* <Modal.Header>
                        <Modal.Title>Loading Minting</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body style={{borderRadius: '50%'}}>
                        <div className="container d-flex h-100">
                            <div className="row justify-content-center align-self-center mx-auto ">
                                <img src={mintingGIF} width="200px"/>                                
                            </div>
                        </div>
                        <br />
                        <br />
                        <h4 className='text-center'>Loading Minting ...</h4>
                        <br />
                    </Modal.Body>
                </Modal>
            ): (
            <section className="appie-hero-area" style={sectionStyle}>
            {/* <section className="appie-hero-area mutan-bg-primary"> */}
            {/* <section className="appie-hero-area mutan-bg-blue"> */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                {/* <span>Welcome To</span> */}
                                {/* <h1 className="appie-title" style={{color: "#120AFF"}} > */}
                                <h1 className="appie-title mutan-text-white">
                                    Lets Build Instantly Your NFT Platform
                                </h1>
                                <ul>
                                    <li> 
                                        {
                                            priceGiftCode !== 0 ?  (
                                                <>
                                                <p style={{color: "#f00", fontWeight:700, fontSize:24}}>
                                                Mint Price:  <strike>{priceOriginal}</strike> ONE, </p>
                                                <p style={{color: "#fff", fontWeight:700, fontSize:24}}> Discount 1: {price} ONE</p>
                                                </>
                                            ) : (
                                                <>
                                                <p style={{color: "#fff", fontWeight:700, fontSize:24}}>
                                                Mint Price: {price} ONE </p>
                                                </>
                                            )
                                        }
                                        <p style={{color: "#fff", fontWeight:'lighter', fontSize:24}}>Supply: {minted}/{supply}</p>
                                        {/* <p style={{color: "#120AFF", fontWeight:700, fontSize:24}}>Mint Price: {price} ONE</p> */}
                                        <br/>
                                        {
                                            allowedNetwork.indexOf(props.networkId) >= 0 ? (
                                                <>
                                                <input type="text" onChange={changeGiftCode} className="form form-sm form-control" placeholder='Input Your Gift Code'></input>
                                                {errorPriceGiftCode !== "" ? ( 
                                                <p className='mt-2' style={{color: "#f00", fontWeight:400, fontSize:18}}>{errorPriceGiftCode}</p>
                                                ):(<></>) }
                                                </>
                                            ) : (
                                                <>
                                                </>
                                            )
                                        }
                                        <br/>
                                        <div className='row'>
                                            {
                                                allowedNetwork.indexOf(props.networkId) >= 0 ? (
                                                    <div className='col-lg-6'>
                                                        <Link className='btn btn-success mutan-bg-build btn-lg' to="/builder">START BUILD NOW</Link>
                                                    </div>
                                                ) : (
                                                    <div className='col-lg-6'>
                                                    </div>
                                                )
                                            }
                                            <div className={allowedNetwork.indexOf(props.networkId) >= 0 ? 'col-lg-6' : 'col-lg-12'}>
                                                {
                                                props.account && props.account.length > 0 ? allowedNetwork.indexOf(props.networkId) >= 0 ? (
                                                    <>
                                                    <a className='btn btn-success mutan-bg-connect btn-lg' onClick={() => mintNFT()} disabled={minting}>
                                                        {minting ? "Loading...": "MINT" }
                                                    </a>
                                                    </>
                                                ) : (
                                                    <a className='btn btn-danger btn-lg' onClick={() => mintNFT()}>
                                                        <small>WRONG NETWORK</small>
                                                    </a>
                                                ) : (
                                                    <a className='btn btn-success mutan-bg-connect btn-lg' onClick={props.connect}>
                                                        <small>CONNECT WALLET</small>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                    style={{paddingTop:-200}}
                                >
                                    <img src={mutans} alt="" width="100%"/>
                                </div>
                                {/* <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={heroThumbTwo} alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" />
                </div>
            </section>
            )}
            
        </>
    );
}

export default HeroHomeOne;
