import React, { useEffect, useState } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ServicesHomeOne from './ServicesHomeOne';
import How from './How';
import ListNFT from './ListNFT';
import Web3 from 'web3';
import WrongNetwork from "./WrongNetwork";
const defaultNetwork = 1666600000;

function HomeOne() {
    const [networkId, setNetworkId] = useState(defaultNetwork);
    const [accountLoaded, setAccountLoaded] = useState(null);
    const [fullAccount, setFullAccount] = useState(null);

    const [drawer, drawerAction] = useToggle(false);
    const wallet = window.ethereum ? new Web3(window.ethereum) : null;
    const [web3, setWeb3] = useState(wallet)

    useEffect(() => {
        checkNetwork();
        checkAccount()
    }, []);

    
    async function checkAccount() {
        try {
            if (web3) {
                let account =  await web3.eth.getAccounts();
                // console.log(account);
    
                let accountlastChar = account[0].substr(account[0].length - 4); // => "Tabs1"
                let accountFirstChar = account[0].substring(0, 6)
                setAccountLoaded(accountFirstChar+"..."+accountlastChar);
                setFullAccount(account);
        
                // console.log("-- account", account[0]);
                // console.log("-- methods", web3.methods);
            } else {
                // console.log('Not Connected')
            }
        } catch {
            // console.log("-- Error");
        }
    }

    async function connectWallet() {
        if (window.ethereum) {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            let account = new Web3(window.ethereum);
            setWeb3(account)
            checkAccount();
            // console.log("Wallet connected")
            return true;
        } else {
            alert('Can\'t Connect Wallet')
        }
    }
    async function checkNetwork() {
        if (web3) {
            let networkId = await web3.eth.getChainId()
            setNetworkId(networkId);
        }
    }

    return networkId !== defaultNetwork ? <WrongNetwork chainId={defaultNetwork} web3={web3}></WrongNetwork> : (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} connect={connectWallet}/>
            <HomeOneHeader action={drawerAction.toggle} web3={web3} accountLoaded={accountLoaded} fullAccount={fullAccount} connect={connectWallet}/>
            <HeroHomeOne web3={web3} accountLoaded={accountLoaded} account={fullAccount} connect={connectWallet}/>
            <ServicesHomeOne />
            <How />
            <ListNFT web3={web3} accountLoaded={accountLoaded} account={fullAccount} connect={connectWallet}/>
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
