import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import mutans from '../../assets/images/mutans.png';
import Background from '../../assets/images/bg_mutan.jpg';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';
import MutanABI from "../../abis/mutan.json";
import swal from 'sweetalert';
import allowedNetwork from "../../config/networkId.json";

function MyNFTPage(props) {
    const [contractNFT, setContractNFT] = useState(null);
    const [minting, setMinting] = useState(false);
    const [price, setPrice] = useState(0);
    const [minted, setMinted] = useState(0);
    const [supply, setSupply] = useState(0);

    useEffect(() => {
        checkNFT();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function checkNFT() {
        try {
            if (props.web3) {
                const contract = new props.web3.eth.Contract(MutanABI[props.networkId].abi, MutanABI[props.networkId].address);
                setContractNFT(contract);

                /* CHECK PRICE NFT AS TOKEN */
                const priceNFT = await contract.methods.mintPrice().call();
                const mintedNFT = await contract.methods.totalSupply().call();
                // const supplyNFT = await contract.methods.MAX_SUPPLY().call();
                setPrice(parseInt(priceNFT) / 1e18);
                setMinted(mintedNFT)
                setSupply(10000);
            } else {
                // console.log("-- Error Connect 1");
            }
        } catch{
            // console.log("-- Error Connect 1.1");
        }
        
    }

    async function mintNFT() {
        if (allowedNetwork.indexOf(props.networkId) >= 0) {
            if (contractNFT) {
                let gas = await contractNFT.methods.mint(0).estimateGas({from: props.account[0], to: MutanABI.address, value: parseInt(price) * 1e18});
                
                contractNFT.methods.mint(0).send({
                    from: props.account[0],
                    gas: gas,
                    value: price * 1e18
                })
                .on('transactionHash', (hash) => {
                    // console.log('Minting with hash', hash)
                    setMinting(true);
                })
                .on('receipt', (receipt) => {
                    // console.log('Receipt', receipt);
                    setMinting(false);
                    swal({
                        title: "Success",
                        text: `Congrats You Got 1 Mutan NFT`,
                        icon: "success",
                        button: "Close"
                    }).then(() => {
                        window.location.reload();
                    })
                })
                .on('error', (error) => {
                    console.error(error)
                })
            }
        } else {
            swal('Warning', `Network ${props.networkId} not allowed`, 'error')
        }
    }

    var sectionStyle = {
        width: "100%",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`
    };

    var sectionStyle2 = {
        width: "100%",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#59c5d5'
    };

    return (
        <>
            <section className="appie-team-area" style={sectionStyle}>
                <div className="container">
                <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="appie-hero-content">
                                <br />
                                <h1 className="appie-title mutan-text-white pt-200 text-center">List Your NFT</h1>
                                <p className='text-center' style={{color: "#fff", fontWeight:700, fontSize:20}}>This Is Your Collectible Mutan to Build Your Own Website</p>

                                <br />
                                <br />
                                <br />
                                
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms">
                                    {/* <img src={heroThumbPrimary} alt="" width="50%"/>
                                    <img src={heroThumbSecondary} alt="" width="50%"/> */}
                                </div>
                            </div>    
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='container'>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MyNFTPage;
