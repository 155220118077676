import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import BuilderABI from "../../abis/builder.json";
// import MutanABI from "../../abis/mutan.json";
import CollectionABI from "../../abis/collection.json";
import allowedNetwork from "../../config/networkId.json";
import iconMutan from "../../assets/images/icon_mutan.png";
import Loader from '../Helper/Loader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTooltip from "react-tooltip";
import { ProgressBar} from "react-bootstrap";
import BuilderListTable from './BuilderListTable';

function BuilderList(props) {
    const [balance, setBalance] = useState(0);
    const [list, setList] = useState([]);
    const [isMounted, setMounted] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    
    useEffect(() => {
        if (allowedNetwork.indexOf(props.networkId) >= 0) {
            getBalance();
            setLoadingPage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getBalance() {
        if (props.web3) {
            // console.log("Get Balance");
            let account = await props.web3.eth.getAccounts()
            let listWeb = [];

            if (account.length > 0) {
                let builderContract = new props.web3.eth.Contract(BuilderABI[props.networkId].abi, BuilderABI[props.networkId].address);
                let b = await builderContract.methods.balanceOf(account[0]).call();
                setBalance(parseInt(b));
                
                // console.log("Fetching RPC");
                for (let i=0; i<b; i++) {
                    let object = {}
                    let websiteInfo = await builderContract.methods.stakedIndex(account[0], i).call();
                    let collectionContract = new props.web3.eth.Contract(CollectionABI[props.networkId].abi, websiteInfo.nftAddress);

                    /* COLLECTION USER */
                    let colName = await collectionContract.methods.name().call();
                    let colSymbol = await collectionContract.methods.symbol().call();
                    let colMintPrice = await collectionContract.methods.mintPrice().call();
                    let colMinted = await collectionContract.methods.totalSupply().call();
                    let colSupply = await collectionContract.methods.MAX_SUPPLY().call();
                    let colDefaultURI = await collectionContract.methods.defaultURI().call();
                    let soulboundAddr = collectionContract.methods.soulboundAddr?await collectionContract.methods.soulboundAddr().call():null;

                    object = {
                        tokenId: websiteInfo.tokenId,
                        index: i,
                        domain: websiteInfo.domain,
                        isLive: websiteInfo.isLive,
                        nftAddress: websiteInfo.nftAddress,
                        collection: {
                            name: colName,
                            symbol: colSymbol,
                            price: parseInt(colMintPrice/1e18),
                            minted: parseInt(colMinted),
                            supply: parseInt(colSupply),
                            uri : colDefaultURI,
                            soulboundAddr: soulboundAddr
                        },
                        deployer: {
                            name: ""
                        }
                    }
                    if (websiteInfo.stakedAddress != "0x0000000000000000000000000000000000000000") {
                        let stakedContract = new props.web3.eth.Contract(CollectionABI[props.networkId].abi, websiteInfo.stakedAddress);
    
                        /* COLLECTION DEPLOYER */
                        let depName = await stakedContract.methods.name().call();
                        const defaultURI = await stakedContract.methods.defaultURI().call()
                        let depMetadata = !defaultURI ? "ipfs://QmSLucAumCAztbu7MiZTHdEAaXNLwevSmQC9cfe91VYzLv/" : defaultURI;
                        depMetadata = depMetadata.replace("ipfs://", "https://cf-ipfs.com/ipfs/");
                        depMetadata += "/" + websiteInfo.tokenId + ".json"

                        object.deployer = {
                            name: depName
                        }

                        await fetch(depMetadata).then(res => res.json()).then(res => {
                            object.deployer.image = res.image.replace("ipfs://", "https://cf-ipfs.com/ipfs/")
                        }).catch((e) => {
                            console.error(e)
                            object.deployer.image = 'https://lpm.ulm.ac.id/image/desain/empty.jpg'
                        })
                    }
                    listWeb.push(object);
                }
                setList(listWeb);
                setLoadingPage(false);
            }
        }
    }

    function listWeb() {
        return (
            <BuilderListTable
                list={list}
                setPrice={setPrice}
                setMetadata={setMetadata}
                setSoulbound={setSoulbound}
                setWLAddr={setWLAddr}
                {...props}
            />
        )
    }

    async function setPrice(_address) {
        swal({
            title: "Set Your NFT Price",
            text: 'Write price as $ONE',
            content: "input",
            button: {
                text: "Save",
                closeModal: false,
            },
        })
        .then(async result => {
            if (!isNaN(result)) {
                let accounts = await props.web3.eth.getAccounts()
                let contract = new props.web3.eth.Contract(CollectionABI[props.networkId].abi, _address);
                let gas = await contract.methods.setPrice(props.web3.utils.toBN(parseInt(result) * 1e18)).estimateGas({from: accounts[0], to: _address});
                
                contract.methods.setPrice(props.web3.utils.toBN(parseInt(result) * 1e18)).send({
                    from: accounts[0],
                    gas: gas
                })
                .on('transactionHash', (hash) => {
                    // console.log('Minting with hash', hash)
                })
                .on('receipt', (receipt) => {
                    // console.log('Receipt', receipt);
                    getBalance();
                    swal({
                        title: "Success",
                        text: `Set Price Success`,
                        icon: "success",
                        button: "Close"
                    })
                })
                .on('error', (error) => {
                    swal("Oh noes!", "Error, Please Try Again", "error");
                })
            } else {
                swal("Oh noes!", "Error, Please Try Again", "error");
            }
        })
        .catch(err => {
            // console.log(err)
            if (err) {
                swal("Oh noes!", "Error, Please Try Again", "error");
            } else {
                swal.stopLoading();
                swal.close();
            }
        });
    }

    async function setMetadata(_address) {
        swal({
            title: "Setting IPFS Metadata",
            text: 'Change it with your url / ipfs metadata directory',
            content: "input",
            button: {
                text: "Save",
                closeModal: false,
            },
        })
        .then(async result => {
            if (result) {
                let accounts = await props.web3.eth.getAccounts()
                let contract = new props.web3.eth.Contract(CollectionABI[props.networkId].abi, _address);
                let gas = await contract.methods.changeDefaultURI(result).estimateGas({from: accounts[0], to: _address});
                
                contract.methods.changeDefaultURI(result).send({
                    from: accounts[0],
                    gas: gas
                })
                .on('transactionHash', (hash) => {
                    // console.log('Change ipfs with hash', hash)
                })
                .on('receipt', (receipt) => {
                    // console.log('Receipt', receipt);
                    swal({
                        title: "Success",
                        text: `Success Change URL Metadata`,
                        icon: "success",
                        button: "Close"
                    })
                })
                .on('error', (error) => {
                    swal("Oh noes!", "Error, Please Try Again", "error");
                })
            } else {
                swal("Oh noes!", "Error, Please Try Again", "error");
            }
        })
        .catch(err => {
            // console.log(err)
            if (err) {
                swal("Oh noes!", "Error, Please Try Again", "error");
            } else {
                swal.stopLoading();
                swal.close();
            }
        });
    }

    async function setSoulbound(_address) {
        swal({
            title: "Are You Sure Wan't to Make it as Soulbond ?",
            text: 'You can\'t undo after execute it',
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
        })
        .then(async result => {
            if (result) {
                let accounts = await props.web3.eth.getAccounts()
                let contract = new props.web3.eth.Contract(CollectionABI[props.networkId].abi, _address);
                let gas = await contract.methods.makeSoulBound().estimateGas({from: accounts[0], to: _address});
                
                contract.methods.makeSoulBound().send({
                    from: accounts[0],
                    gas: gas
                })
                .on('transactionHash', (hash) => {
                    // console.log('Minting with hash', hash)
                })
                .on('receipt', (receipt) => {
                    // console.log('Receipt', receipt);
                    swal({
                        title: "Success",
                        text: `Set Soulbound Success`,
                        icon: "success",
                        button: "Close"
                    })
                })
                .on('error', (error) => {
                    swal("Oh noes!", "Error, Please Try Again", "error");
                })
            } else {
                swal("Oh noes!", "Error, Please Try Again", "error");
            }
        })
        .catch(err => {
            // console.log(err)
            if (err) {
                swal("Oh noes!", "Error, Please Try Again", "error");
            } else {
                swal.stopLoading();
                swal.close();
            }
        });
    }

    async function setWLAddr(_address) {
        let contract = new props.web3.eth.Contract(CollectionABI[props.networkId].abi, _address);
        if (contract.methods.setWhitelist) {
            swal({
                title: "Add Whitelist Address",
                text: 'Add Whitelist Address to Get Free Your NFT',
                content: "input",
                button: {
                    text: "Save",
                    closeModal: false,
                },
            })
            .then(async result => {
                if (result) {
                    let accounts = await props.web3.eth.getAccounts()
                    let gas = await contract.methods.setWhitelist(result, 1, 0).estimateGas({from: accounts[0], to: _address});
                    
                    contract.methods.setWhitelist(result, 1, 0).send({
                        from: accounts[0],
                        gas: gas
                    })
                    .on('transactionHash', (hash) => {
                        // console.log('Change ipfs with hash', hash)
                    })
                    .on('receipt', (receipt) => {
                        // console.log('Receipt', receipt);
                        swal({
                            title: "Success",
                            text: `Success Add Whitelist Address`,
                            icon: "success",
                            button: "Close"
                        })
                    })
                    .on('error', (error) => {
                        swal("Oh noes!", "Error, Please Try Again", "error");
                    })
                } else {
                    swal("Oh noes!", "Error, Please Try Again", "error");
                }
            })
            .catch(err => {
                // console.log(err)
                if (err) {
                    swal("Oh noes!", "Error, Please Try Again", "error");
                } else {
                    swal.stopLoading();
                    swal.close();
                }
            });
        } else {
            swal("Oh noes!", "Collection Not Support for Whitelist Address", "error");
        }
    }

    return (
        <div className='col-md-12 col-lg-12 col-sm-12'>
            <br/>
            {loadingPage ? (
                <>
                <div className='row h-100 justify-content-center align-items-center'>
                    <Loader />
                </div>
                <br></br>
                <br></br>
                </>
            ) :listWeb()}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default BuilderList;