import React from 'react';
import thumbYellow from '../../assets/images/mutan-howitswork.png';

function How(props) {
    
    return (
        <>
            <section id="how-its-work" className="appie-how-it-work-area pt-100 pb-100" style={{background: '#02ccff'}} >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={thumbYellow} alt="" width="70%" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-how-it-work-content">
                                <h2 className="title text-white">How It Works ?</h2>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span className='mutan-text-blue'>1</span>
                                            <h5 className="title text-white">
                                                Creators must have NFT from us
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span className='mutan-text-blue'>2</span>
                                            <h5 className="title text-white">
                                                Creators can create the minting websites with purchased NFT
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span className='mutan-text-blue'>3</span>
                                            <h5 className="title text-white">
                                                Stake NFT as a guarantee for the web hosting
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span className='mutan-text-blue'>4</span>
                                            <h5 className="title text-white">
                                                Creators can choose the appearance for the minting website
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="how-it-work-box">
                                            <span className='mutan-text-blue'>5</span>
                                            <h5 className="title text-white">
                                            NFTs can be unstaked and sold on secondary market, but minting website service will automatically off
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </section>
            <section id="how-its-work" className="appie-how-it-work-area pt-100 pb-100 mutan-bg-white" >
                <div className="container">
                    <div className='col-lg-12'>
                        <div className="appie-how-it-work-content">
                            <h2 className="title">Step By Step</h2>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span className='mutan-text-blue'>1</span>
                                        <h5 className="title">
                                            Mint the Mutan NFT
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span className='mutan-text-blue'>2</span>
                                        <h5 className="title">
                                            Create collection and then Stake your Mutan NFT
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span className='mutan-text-blue'>3</span>
                                        <h5 className="title">
                                            Set your IPFS metadata
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span className='mutan-text-blue'>4</span>
                                        <h5 className="title">
                                            Now your minting website can be accessed
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default How;
