import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/mutan_logo_navy.png';

function Drawer({ drawer, action }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (e, value) => {
        e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
        action(e);
    };
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <span onClick={(e) => action(e)}>
                                        <i className="fa fa-times text-white"></i>
                                    </span>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} width="200" alt="" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li
                                            onClick={(e) => handler(e, 'home')}
                                            id="home"
                                            className="menu-item-has-children active">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li
                                            id="page-how-its-work"
                                            className="menu-item-has-children">
                                            <a href="/#how-its-work">How It's Work</a>
                                        </li>
                                        <li
                                            id="page-my-nft"
                                            className="menu-item-has-children">
                                            <a href="/my-nft">My NFT</a>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'builder')}
                                            id="builder"
                                            className="menu-item-has-children">
                                            <Link to="/builder">Builder</Link>
                                        </li>
                                        <li
                                            id="docs"
                                            className="menu-item-has-children">
                                            <a target="_blank" href="https://docs.mutan.one/roadmap">Roadmap</a>
                                        </li>
                                        <li
                                            id="docs"
                                            className="menu-item-has-children">
                                            <a target="_blank" href="https://docs.mutan.one">Docs</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
