import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/mutan_white.png';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../Navigation';
import { Modal, Button, Dropdown} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Background from '../../assets/images/bg_mutan.jpg';

function HomeOneHeader({ action, web3, accountLoaded, fullAccount }) {
    const [modal, setModal] = useState(false);

    useEffect(() => {
        StickyMenu();
        // connect();
    }, []);

    async function connect() {
        if (window.ethereum) {
            await window.ethereum.request({ method: 'eth_requestAccounts' }).then(handleAccountsChanged)
            .catch((err) => {
                if (err.code === 4001) {
                    console.log('Please connect to MetaMask.');
                } else {
                    console.error(err);
                }
            });
        } else {

        }
    }

    function handleAccountsChanged(accountLoaded) {
        console.log(accountLoaded);
    
        if (accountLoaded.length === 0) {
            console.log("You're not connected to MetaMask");
            // $('#connection-status').innerText = "You're not connected to MetaMask";
            // $('#connect-btn').disabled = false;
        } else {
            // console.log("---", currentAccount);
            window.location.reload();
            // $('#connection-status').innerText = `Address: ${currentAccount}`;
            // $('#connect-btn').disabled = true;
         }
    }

    async function logout () {
        // initialise();
    }

    function modalAccountOpen () {
        setModal(true);
    }

    function modalAccountClose () {
        setModal(false);
    }

    var sectionStyle2 = {
        width: "100%",
        backgroundPosition: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`
    };

    return (
        <>
        <header className="appie-header-area appie-sticky" style={sectionStyle2}>
        {/* <header className="appie-header-area appie-sticky mutan-bg-primary"> */}
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                            <div className="appie-logo-box">
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-1 order-3 order-sm-2">
                            <div className="appie-header-main-menu">
                                <Navigation />
                            </div>
                        </div>
                        <div className="col-lg-2  col-md-4 col-sm-6 col-6 order-2 order-sm-3">
                            <div className="appie-btn-box text-right">
                                { accountLoaded ?
                                    (
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle  id="dropdown-basic" className='mutan-bg-connect-header'>
                                                    {accountLoaded === null ? "Connect Wallet" : accountLoaded}  
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu style={{ margin: 0 }}>
                                                    <Dropdown.Item onClick={() => modalAccountOpen()}>Account Detail</Dropdown.Item>
                                                    {/* <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                        </div>
                                    ) : <div><button className='btn btn-primary' style={{cursor: "pointer", backgroundColor: "#120AFF", color : "#fff"}} onClick={() => connect()}>Connect</button></div>
                                }
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block">
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <Modal show={modal}>
            <Modal.Header>
                <Modal.Title>Account Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>{fullAccount}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => modalAccountClose()}>
                Close
                </Button>
                {/* <Button variant="danger" >
                Logout
                </Button> */}
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default HomeOneHeader;
